import styled from 'styled-components'
import { ColumnWrapper } from './styled-components/Wrappers.styled'

export const ExternalMarkupWrapper = styled(ColumnWrapper)`
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    margin: auto;
    h4 {
        padding-left: 5%;
    }
    h5 {
        padding-left: 5%;
    }
    p {
        padding-left: 5%;
    }

    ol li {
        list-style-type: decimal;
        padding: 15px 0 15px;
    }

    ul li {
        list-style-type: circle;
        margin-left: 5%;
        padding: 10px 0 10px;
    }
    a {
        color: black;
        text-decoration: underline;
    }
`
