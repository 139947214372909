import React from 'react'
import { Link } from 'gatsby'
import Button from './styled-components/Button'
import { H2Section } from './styled-components/Headings.styled'
import { ExternalMarkupWrapper } from './ExternalMarkupArticle.styled'

const ExternalMarkupArticle = ({ title, width, children }) => {
    return (
        <ExternalMarkupWrapper
            as="article"
            width={width}
            padding="50px"
            margin="auto"
        >
            <H2Section>{title}</H2Section>
            {children}
            <Button light as={Link} to="/" aria-label="Wróć do strony głównej">
                Wróć do strony głównej
            </Button>
        </ExternalMarkupWrapper>
    )
}

export default ExternalMarkupArticle
