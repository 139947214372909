import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/layout/Layout'
import ExternalMarkupArticle from '../components/ExternalMarkupArticle'

const PrivacyPolicy = () => {
    const data = useStaticQuery(graphql`
        query PrivacyPolicyQuery {
            wpgraphql {
                page(id: "cG9zdDoz") {
                    id
                    content(format: RENDERED)
                }
            }
        }
    `)

    const createMarkup = () => ({
        __html: data.wpgraphql.page.content,
    })

    const memoizedMarkup = useMemo(() => createMarkup())

    return (
        <Layout headerMode="shop">
            <SEO title="Polityka prywatności" />
            <ExternalMarkupArticle title="Polityka prywatności" width="960px">
                <div
                    style={{ padding: 20 }}
                    dangerouslySetInnerHTML={memoizedMarkup}
                />
            </ExternalMarkupArticle>
        </Layout>
    )
}

export default PrivacyPolicy
