import styled from 'styled-components'

const TileTitle = styled.span`
    font-family: 'Futura T OT Medium';
    text-transform: uppercase;
    font-size: 2.8rem;
    align-self: flex-start;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    text-shadow: ${({ highContrast }) => '2px 2px 2px rgba(0, 0, 0, 0.3)'};
`

export const TileUpperTitle = styled(TileTitle)`
    color: ${({ theme }) => theme.colors.primary};
`

export const TileLowerTitle = styled(TileTitle)`
    color: ${({ theme }) => theme.colors.tertiary};
`

export const H2Section = styled.h2`
    margin: 20px 0 20px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.4rem;
    font-family: 'Futura T OT Medium';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
`

export const H3wBackFill = styled.h3`
    padding: 1rem 3rem 1rem;
    font-size: 4rem;
    letter-spacing: 0.8px;
    background-color: ${({ theme, primary }) =>
        primary ? theme.colors.primary : theme.colors.dark};
    color: ${({ theme, primary }) =>
        primary ? theme.colors.dark : theme.colors.light};
    z-index: 10;
    overflow: hidden;
    font-family: 'Futura T OT';
    min-width: 280px;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.media.phone}) {
        font-size: 2.6rem;
    }
`
